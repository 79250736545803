import { css } from 'styled-components';

/**
 * Round x with n decimal.
 * @param {Number} x Number to be rounded.
 * @param {Number} n Number of digits after semi column.
 * @returns {Number} Number rounded.
 */
// const round = (x, n) => Math.round(x * (n * 10)) / (n * 10);

// const scaleN = n => round(n ** 1.2, 3);

// const scale = n => `${scaleN(n)}rem`;

const color = {
  accentDarker: '#DC4217',
  accent: '#F0582E',
  accentLighter: '#FE7049',
  primaryDarker: '#71C4B4',
  primary: '#8ACAC9',
  primaryLighter: '#A0E5D8',
  secondary: '#FDCF41',
  foreground: '#080B0B',
  background: '#E6EDED',
  white: '#FFFFFF',

  orange: '#F55428',
  yellow: '#FDCF41',
  turquoise: '#8ACAC9',
  textColor: '#535555',
};

const typography = {
  fontSize: 16,
  lineHeight: 1.5,
  headerFontFamily: 'English-Monsters, sans-serif',
  bodyFontFamily: 'English-Monsters, sans-serif',
};

const sizes = {
  desktop: 1440,
  laptop: 1024,
  tablet: 600,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

const theme = {
  color,
  typography,
  media,
  maxWidth: '1800px',
  bg: color.black,
  gap: '1em',
  borderRadius: '0.2rem',
};

export default theme;
