import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
// import Logo from '@images/logo.svg';
import { Wrapper } from '@shared';

const HeaderWrapper = styled(Wrapper)`
  padding: 0.75em 0;

  ${({ theme }) => theme.media.laptop`
    padding: 0.75em 0;
  `}
`;

// const HeadLogo = styled(Logo)`
//   width: 74px;
//   height: auto;
// `;

const Header = () => {
  return (
    <HeaderWrapper>
      <Link to="/">{/* <HeadLogo /> */}</Link>
    </HeaderWrapper>
  );
};

export default Header;
