/* eslint-disable react/prop-types */
import React from 'react';
import Layout from '@layouts/default';
import { SEO } from '@shared';
import Hero from './Hero';

const HomePageTemplate = ({ location, htmlTitle, htmlDescription, htmlKeywords, title }) => (
  <Layout location={location}>
    <SEO shortTitle title={htmlTitle} description={htmlDescription} keywords={htmlKeywords} />
    <Hero title={title} />

    {/* <section>
      <h2>Who am I?</h2>
    </section> */}
  </Layout>
);

export default HomePageTemplate;
