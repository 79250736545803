/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import 'intl';
import { IntlProvider } from 'react-intl';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { theme, GlobalStyle } from '@utils';

import { Header, Footer } from '@widgets';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
`;

const siteQuery = graphql`
  query SiteQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;

const Layout = ({ children, location }) => (
  <StaticQuery
    query={siteQuery}
    render={(data) => {
      const url = location.pathname;
      const { langs, defaultLangKey } = data.site.siteMetadata.languages;
      const langKey = getCurrentLangKey(langs, defaultLangKey, url);
      const homeLink = `/${langKey !== defaultLangKey ? langKey : ''}`;
      const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({
        ...item,
        link: item.link.replace(`/${defaultLangKey}/`, '/'),
      }));

      const i18nMessages = require(`../../data/messages/${langKey}`);

      return (
        <ThemeProvider theme={theme}>
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <Wrapper>
              <GlobalStyle />
              <Helmet>
                <html lang={langKey} />
              </Helmet>
              {/* <Header langs={langsMenu} /> */}
              <Content>{children}</Content>
              {/* <Footer /> */}
            </Wrapper>
          </IntlProvider>
        </ThemeProvider>
      );
    }}
  />
);

export default Layout;
