import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.header`
  width: 100%;
  max-width: 75%;
  z-index: 1;
`;

const H1 = styled.h1`
  margin-bottom: 0.3em;
`;

const Description = styled.p`
  margin: 0;
  margin-bottom: 0.5em;
  line-height: 1.15;
`;

const wave1 = keyframes`{
    from {
        transform: rotate(0) translate3d(0,0,-100px)
    }

    from {
        transform: rotate(360deg) translate3d(0,0,-100px)
    }
}`;

const wave2 = keyframes`{
    from {
        transform: rotate(0) translate3d(0,0,-50px)
    }

    from {
        transform: rotate(-360deg) translate3d(0,0,-50px)
    }
}`;

const wave3 = keyframes`{
    from {
        transform: rotate(0) translate3d(0,0,0)
    }

    from {
        transform: rotate(-360deg) translate3d(0,0,0)
    }
}`;

const wave4 = keyframes`{
    from {
        transform: rotate(0) translate3d(0,0,0)
    }

    from {
        transform: rotate(360deg) translate3d(0,0,0)
    }
}`;

const Waves = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  ::after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    z-index: 11;
    transform: translate3d(0, 0, 0);
  }
`;

const Wave = styled.div`
  position: absolute;
  left: 50%;
  width: 150vmax;
  height: 150vmax;
  margin-left: -75vmax;
  margin-top: 3vmax;
  border-radius: 43%;
  box-shadow: 10px 10px 168px -20px rgba(0, 0, 150, 0.1);
  background: #fff;
`;

const WaveOne = styled(Wave)`
  top: 15%;
  transform-origin: 50% 48%;
  animation: ${wave1} 75s infinite linear;
`;

const WaveTwo = styled(Wave)`
  top: 30%;
  transform-origin: 51% 47%;
  animation: ${wave2} 80s infinite -5s linear;
`;

const WaveThree = styled(Wave)`
  top: 55%;
  margin-left: -90vmax;
  width: 180vmax;
  height: 180vmax;
  transform-origin: 48% 48%;
  animation: ${wave3} 90s infinite -15s linear;
`;

const WaveFour = styled(Wave)`
  top: 75%;
  margin-left: -100vmax;
  width: 200vmax;
  height: 200vmax;
  transform-origin: 48% 49%;
  animation: ${wave4} 78s infinite -2s linear;
`;

const Hero = ({ title }) => (
  <Container>
    <Header>
      <H1>{title}</H1>
      <Description>Product Manager</Description>
      <Description>Web Analyst</Description>
      <Description>Frontend Developer</Description>
    </Header>

    <Waves>
      <WaveOne />
      <WaveTwo />
      <WaveThree />
      <WaveFour />
    </Waves>
  </Container>
);

export default Hero;
