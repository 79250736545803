import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;

    ${({ theme }) => theme.media.tablet`
      font-size: calc(16px + (32 - 12)*(100vw - 600px)/(1440 - 600));
    `}

    ${({ theme }) => theme.media.desktop`
      font-size: 32px;
    `}
  }

`;

export default GlobalStyle;
