import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background-color: #212222;
  text-align: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'nav'
    'social';
  max-width: ${({ theme }) => theme.maxWidth};
  padding: 4em 2em;
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: auto 1fr;
    grid-template-areas: 
      "logo logo"
      "nav nav"
      "social social";
  `}

  ${({ theme }) => theme.media.laptop`
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 
      "logo nav nav"
      "social social social";
  `}
`;

const FooterNav = styled.nav`
  grid-area: nav;
`;

const FooterLink = styled.a`
  display: inline-block;
  text-decoration: none;
  margin-right: 1em;
  margin-bottom: 0.75em;

  /* &,
  :hover,
  :focus,
  :active,
  :visited {
    color: ${({ theme }) => theme.color.foreground};
  } */

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export default () => (
  <Footer>
    <Wrapper>
      <FooterNav>
        <FooterLink to="#">Методика</FooterLink>
        <FooterLink to="#">Послуги</FooterLink>
        <FooterLink to="#">Календар</FooterLink>
        <FooterLink to="#">Вчителі</FooterLink>
        <FooterLink to="#">Блог</FooterLink>
        <FooterLink to="#">Мерч</FooterLink>
      </FooterNav>
    </Wrapper>
  </Footer>
);
